/** @jsx jsx */
import { Container, Row, Col } from "react-bootstrap";
import BlogCard from "@/components/blog-card";
import { jsx } from "@emotion/react";
import {commonSection} from "../assets/styles/layout.styles";

const BlogOne = ({posts, url}) => {

  return (
    <section style={{marginTop: '100px'}} css={commonSection} className="blogPage">
      <Container>
        <Row>
          {posts.map((post, index) => (
            <Col lg={4} sm={12} md={6} key={index}>
              <BlogCard url={url} data={post} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default BlogOne;